import {Col, Container, Row} from "react-bootstrap";
import mainBanner from "../assets/honda/temp/banner1.jpg";

import model1 from "../assets/honda/temp/civictyper.png"
import model2 from "../assets/honda/temp/brio.png"
import TextFormatter from "../util/TextFormatter";
import {Link, useNavigate} from "react-router-dom";

const demoCarData = [
    {
        id : 1,
        name : "Honda Civic Type R",
        main_image : model1,
        price : 1177000000
    },
    {
        id : 1,
        name : "New Honda Brio",
        main_image : model2,
        price : 157900000
    }
]

export default function Home(props){
    document.title = 'Home';

    let navigate = useNavigate()

    return <Container fluid>
        <section title={"Hero"}>
            <Row>
                <Col
                    style={{
                        padding : 0
                    }}
                    md={12}>
                    <img
                        style={{
                            width : "100%"
                        }}
                        src={mainBanner}/>
                </Col>
            </Row>
        </section>
        <section title={"Catalogue"}>
            <div style={{width : "100%"}} className={"d-flex flex-column align-items-center"}>
                <Row style={{width : "100%", maxWidth : 1000, alignSelf : "center", justifySelf : "center"}}>
                    <Col
                        style={{marginTop : 35, marginBottom : 35}}
                        md={12}>
                        <h3 style={{fontWeight : "700"}}>MODELS</h3>
                    </Col>
                    {
                        [...demoCarData,...demoCarData,...demoCarData].map((obj,key)=>{
                            return <Col
                                style={{
                                    paddingTop : 10,
                                    paddingBottom : 10,
                                    marginBottom : 50,
                                }}
                                onClick={()=>{
                                    navigate("/detail")
                                }}
                                className={"d-flex flex-column align-items-center justify-content-center"}
                                md={4}>
                                <Link style={{width : "100%"}} to={"/detail"}>
                                    <div
                                        style={{width : "100%"}}
                                        className={"d-flex flex-column align-items-center justify-content-center"}>
                                        <img
                                            style={{
                                                height : 120,
                                                width : "100%",
                                                objectFit : "contain",
                                                objectPosition : "bottom"
                                            }}
                                            src={obj.main_image}/>
                                        <div style={{fontWeight : 700, marginTop : 10, marginBottom : 5}}>
                                            {obj.name}
                                        </div>
                                        <div style={{width : "100%", paddingLeft : 20, paddingRight : 20}}>
                                            <div style={{
                                                border : "2px solid black",
                                                borderRadius : 30,
                                                width : "100%",
                                                paddingTop : 5,
                                                paddingBottom : 5
                                            }}>
                                                <div style={{fontSize : "0.7em"}}>Harga Mulai Dari</div>
                                                <div style={{fontWeight : 700}}>{TextFormatter.formatPrice(obj.price)}*</div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        })
                    }
                </Row>
            </div>
        </section>
    </Container>
}