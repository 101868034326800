import {Col, Container, Modal, Row} from "react-bootstrap";
import mainBanner from "../assets/honda/temp/brio/briobanner.png";

import variant1 from "../assets/honda/temp/brio/variant/v1.png"

import color1 from "../assets/honda/temp/brio/color/1.png"
import color2 from "../assets/honda/temp/brio/color/2.png"
import color3 from "../assets/honda/temp/brio/color/3.png"
import color4 from "../assets/honda/temp/brio/color/4.png"
import color5 from "../assets/honda/temp/brio/color/5.png"
import color6 from "../assets/honda/temp/brio/color/6.png"
import color7 from "../assets/honda/temp/brio/color/b7.png"
import color8 from "../assets/honda/temp/brio/color/b8.png"
import color9 from "../assets/honda/temp/brio/color/b9.png"

import gallery1 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_13a__1613316309591.jpg"
import gallery2 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_14__1613316235914.jpg"
import gallery3 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_15__1613316147328.jpg"
import gallery4 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_16__1613316105009.jpg"
import gallery5 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_17__1613316049899.jpg"
import gallery6 from "../assets/honda/temp/brio/gallery/banner_web_brio_3_final_32__1613318578995.jpg"

import TextFormatter from "../util/TextFormatter";
import Palette from "../util/Palette";
import Iconify from "../reusable/Iconify";
import {useState} from "react";

const colors = [
    {
        name: "Crystal Black Pearl",
        image: color1
    },
    {
        name: "Taffeta White",
        image: color2
    },
    {
        name: "Modern Steel Metallic",
        image: color3
    },
    {
        name: "Carnival Yellow",
        image: color4
    },
    {
        name: "Rallye Red",
        image: color5
    },
    {
        name: "Carnival Yellow - Two Tone (URBANITE Edition)",
        image: color6
    },
    {
        name: "Phoenix Orange Pearl - Two Tone (URBANITE Edition)",
        image: color7
    },
    {
        name: "Taffeta White - Two Tone (URBANITE Edition)",
        image: color8
    },
    {
        name: "Crystal Black Pearl (URBANITE Edition)",
        image: color9
    },
]

const galleries = [
    {
        title: "Leather Seats",
        image: gallery1,
    },
    {
        title: "Interactive Buttons",
        image: gallery2,
    },
    {
        title: "Adjustable Steering Wheel",
        image: gallery3,
    },
    {
        title: "6\" LCD Display",
        image: gallery4,
    },
    {
        title: "Spacy Interior",
        image: gallery5,
    },
    {
        title: "World Class Safety",
        image: gallery6,
    }
]

export default function ItemDetail(props) {

    let [selectedGallery, setSelectedGallery] = useState(null)
    document.title = 'Item Detail';

    return <>
        <Container fluid style={{padding: 0}}>
            <section title={"Hero"}>
                <Row>
                    <Col
                        style={{
                            padding: 0
                        }}
                        md={12}>
                        <img
                            style={{
                                width: "100%"
                            }}
                            src={mainBanner}/>
                    </Col>
                </Row>
            </section>
            <section title={"Variant"}>
                <Row style={{marginTop: 50}}>
                    <div style={{width: "100%"}} className={"d-flex flex-column align-items-center"}>
                        <Row style={{width: "100%", maxWidth: 1000, alignSelf: "center", justifySelf: "center"}}>
                            <Col
                                style={{marginTop: 35, marginBottom: 35}}
                                md={12}>
                                <h3 style={{fontWeight: "700"}}>VARIANT</h3>
                            </Col>
                            {
                                [1, 2, 3].map((obj, key) => {
                                    return <Col
                                        style={{
                                            paddingTop: 10,
                                            paddingBottom: 10,
                                            marginBottom: 50,
                                        }}
                                        className={"d-flex flex-column align-items-center justify-content-center"}
                                        md={4}>
                                        <h5 style={{fontWeight: "700"}}>Honda Brio Satya S M/T</h5>
                                        <img
                                            style={{
                                                height: 120,
                                                width: "100%",
                                                objectFit: "contain",
                                                objectPosition: "bottom",
                                                marginTop: 30
                                            }}
                                            src={variant1}/>
                                        <div style={{width: "100%", paddingLeft: 20, paddingRight: 20, marginTop: 20}}>
                                            <div style={{
                                                // border : `2px solid ${Palette.PRIMARY_COLOR}`,
                                                background: Palette.PRIMARY_COLOR,
                                                color: "white",
                                                borderRadius: 30,
                                                width: "100%",
                                                paddingTop: 5,
                                                paddingBottom: 5
                                            }}>
                                                <div style={{fontSize: "0.7em"}}>Harga</div>
                                                <div style={{fontWeight: 700}}>{TextFormatter.formatPrice(159100000)}*
                                                </div>
                                            </div>
                                        </div>
                                        <h5 style={{fontWeight: "400", marginTop: 20}}>Spesifikasi</h5>
                                        <div style={{width: "100%", paddingLeft: 20, paddingRight: 20, marginTop: 20}}>
                                            <div style={{
                                                // border : `2px solid ${Palette.PRIMARY_COLOR}`,
                                                background: "#f3f4f6",
                                                width: "100%",
                                                paddingTop: 20,
                                                paddingBottom: 5,
                                                paddingLeft: 5,
                                                paddingRight: 5,
                                                textAlign: "left",
                                                fontSize: "0.95em"
                                            }}>
                                                <ul>
                                                    <li>1.2 i-VTEC 90PS</li>
                                                    <li>5 M/T</li>
                                                    <li>Chrome Front Grille</li>
                                                    <li>Headlamp with LED Light Guide</li>
                                                    <li>14" Trim Wheels</li>
                                                    <li>Body Spoiler with LED High Mount Stop Lamp</li>
                                                    <li>Two Tone Interior Color</li>
                                                    <li>2nd Row Adjustable Headrest</li>
                                                    <li>Tilt Steering</li>
                                                    <li>Electric Power Steering</li>
                                                    <li>Digital A/C</li>
                                                    <li>ABS + EBD</li>
                                                    <li>Parking Sensor</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                })
                            }
                        </Row>
                    </div>
                </Row>
            </section>
            <section title={"Color"}>
                <Row>
                    <div style={{width: "100%"}} className={"d-flex flex-column align-items-center"}>
                        <Row style={{width: "100%", maxWidth: 1000, alignItems: "center", justifyContent: "center"}}>
                            <Col
                                style={{marginTop: 35, marginBottom: 50}}
                                md={12}>
                                <h3 style={{fontWeight: "700"}}>COLORS</h3>
                            </Col>
                            {
                                colors.map((obj, key) => {
                                    return <Col
                                        style={{marginBottom: 20}} md={3}>
                                        <img
                                            title={obj.name}
                                            style={{
                                                height: 80,
                                                width: "100%",
                                                objectFit: "contain"
                                            }}
                                            src={obj.image}/>
                                        <div style={{
                                            fontWeight: 100,
                                            marginTop: 5,
                                            marginBottom: 10
                                        }}>
                                            {obj.name}
                                        </div>
                                    </Col>
                                })
                            }
                        </Row>
                    </div>
                </Row>
            </section>
            <section title={"CTA"}>
                <Row style={{
                    background: Palette.PRIMARY_COLOR,
                    marginTop: 70
                }}>
                    <Col
                        className={"d-flex align-items-center justify-content-center"}
                        style={{
                            paddingTop: 30,
                            paddingBottom: 30
                        }}
                        md={12}>
                        <div style={{
                            width: 300,
                            border: "3px solid white",
                            paddingLeft: 50,
                            paddingRight: 50,
                            paddingTop: 10,
                            paddingBottom: 10,
                            fontSize: "1.1em",
                            fontWeight: 700,
                            color: "white"
                        }}>
                            Unduh Brosur <Iconify icon={"ic:file-download"}/>
                        </div>
                    </Col>
                </Row>
            </section>
            {/*<section title={"Galeri"}>*/}
            <Row style={{width: "100%", alignSelf: "center", justifySelf: "center", padding: 0, margin: 0}}>
                <Col
                    style={{marginTop: 35, marginBottom: 35}}
                    md={12}>
                    <h3 style={{fontWeight: "700"}}>GALERI</h3>
                </Col>
                {
                    galleries.map((obj, key) => {
                        return <Col
                            onClick={() => {
                                setSelectedGallery(obj)
                            }}
                            className={"d-flex flex-column"}
                            style={{
                                height: 300,
                                backgroundSize: "cover",
                                background: `url(${obj.image})`,
                                backgroundPosition: "center",
                                padding: 0,
                                cursor: "pointer"
                            }}
                            md={4}>
                            <div
                                className={"d-flex justify-content-end align-items-end"}
                                style={{
                                    flex: 1,
                                    background: "#00000040",
                                    color: "white",
                                    padding: 10,
                                    paddingRight: 20,
                                    paddingLeft: 30
                                }}>
                                <div style={{
                                    fontWeight: 700,
                                    borderTop: "1px solid white",
                                    width: "100%",
                                    textAlign: "right"
                                }}>
                                    {obj.title}
                                </div>
                            </div>
                        </Col>
                    })
                }
            </Row>
            {/*</section>*/}
        </Container>
        <Modal
            size={"lg"}
            show={!!selectedGallery}
            onHide={() => {
                setSelectedGallery(null)
            }}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Container fluid>
                <Row style={{padding: 0, background: "#eaeaea"}}>
                    <Col
                        className={"d-flex flex-row"}
                        style={{
                            padding: 10, fontWeight: 700,
                            paddingRight: 15
                        }}
                        md={12}>
                        <div style={{flex: 1}}>{selectedGallery?.title}</div>
                        <div
                            onClick={() => {
                                setSelectedGallery(null)
                            }}
                            style={{
                                cursor: "pointer"
                            }}>
                            x
                        </div>
                    </Col>
                </Row>
                <Row style={{padding: 0, background: "#eaeaea"}}>
                    <Col
                        style={{
                            padding: 0
                        }}
                        md={8}>
                        <img
                            src={selectedGallery?.image}
                            style={{
                                width: "100%",
                                objectFit: "contain"
                            }}
                        />
                    </Col>
                    <Col
                        style={{
                            background: "#333333",
                            color: "white",
                            padding: 15
                        }}
                        md={4}>
                        <div style={{fontWeight: "bold"}}>
                            {selectedGallery?.name}
                        </div>
                        <div style={{fontSize: "0.8em", marginTop: 10}}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                            It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing software like Aldus PageMaker including
                            versions of Lorem Ipsum.
                        </div>
                    </Col>
                </Row>
            </Container>
        </Modal>
    </>
}