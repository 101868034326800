import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Router from './Routes';
import {BrowserRouter} from "react-router-dom";
import {RemoveScrollBar} from 'react-remove-scroll-bar';
import ScrollToTop from "./components/reusable/RouterScrollToTop";
import { useGA4React } from "ga-4-react";


function App() {
  const ga = useGA4React();
  console.log(ga);
  return (
    <div className="App">
        {/*<RemoveScrollBar />*/}
        <BrowserRouter>
            <ScrollToTop />
            <Router/>
        </BrowserRouter>
    </div>
  );
}

export default App;
