import PageContainer from "./components/reusable/PageContainer";
import NotFound from "./components/pages/NotFound";
import Home from "./components/pages/Home";
import ItemDetail from "./components/pages/ItemDetail";
import {useRoutes} from "react-router-dom";

export default function Router() {
    const routes = [
        {
            path: '/',
            element: <PageContainer />,
            children: [
                { path: '/', element: <Home /> },
                { path: '/detail', element: <ItemDetail /> },
                { path: '*', element: <NotFound /> }
            ]
        },
    ]
    return useRoutes(routes)
}