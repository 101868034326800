import {Link, Outlet} from "react-router-dom";
import {Button, Col, Container, Navbar, Row} from "react-bootstrap";
import Iconify from "./Iconify";

import logo from "../assets/honda/logo.png"
import Palette from "../util/Palette";
import {useEffect, useState} from "react";

export default function PageContainer({}) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])


    const isMobile = windowWidth < 768

    return (
        <>
            <div fluid style={{
                boxShadow : "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                webkitBoxShadow : "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                mozBoxShadow : "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                position : "fixed",
                width : "100%",
                background : "#ffffffcc"
            }}>
                <Container style={{display : "flex", flexDirection : "column", alignItems : "center", width : "100%"}}>
                    <Row style={{maxWidth : 1000, width :"100%"}}>
                        <Col className={"d-flex flex-row align-items-center"} md={2}>
                            {/*<Navbar.Brand>*/}
                            {/*    <Iconify icon={"mdi:hamburger-menu"}/>*/}
                            {/*</Navbar.Brand>*/}
                        </Col>
                        <Col className={"d-flex flex-row align-items-center justify-content-center mt-3 mb-3"} md={8}>
                            <Link to={"/"}>
                                <img
                                    height={50}
                                    src={logo}/>
                            </Link>
                        </Col>
                        {
                            !isMobile &&
                            <Col style={{fontWeight : "900"}} className={"d-flex flex-row align-items-center"} md={2}>
                                <a href={"https://wa.me/+6281213389818"} target={"_blank"}>
                                    HUBUNGI KAMI
                                </a>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
            <div style={{height : "calc(2rem + 50px)"}}>

            </div>
            <Outlet/>

            <Container fluid style={{
                display : "flex",
                flexDirection : "column",
                alignItems : "center",
                justifyContent : "center",
                width : "100%",
                background : Palette.PRIMARY_COLOR,
                height : 250,
                color : "white",
                fontWeight : 700,
            }}>
                <div style={{
                    border : "3px solid white",
                    paddingLeft : 50,
                    paddingRight : 50,
                    paddingTop : 10,
                    paddingBottom : 10,
                    fontSize : "1.1em"
                }}>
                    <a href={"https://wa.me/+6281213389818"} target="_blank">
                        Hubungi Kami !
                    </a>
                </div>
            </Container>

            <div>
                <Container fluid style={{display : "flex", flexDirection : "column", alignItems : "center", width : "100%", background : Palette.GREY, paddingBottom : 30, paddingTop : 50}}>
                    <Row style={{maxWidth : 1000, width :"100%"}}>
                        <Col
                            className={"d-flex flex-column justify-content-start align-items-start"}
                            md={6}>
                            <Container fluid>
                                <Row>
                                    <Col
                                        style={{paddingBottom : 20}}
                                        className={"d-flex flex-column justify-content-start align-items-start"}
                                        md={6}>
                                        <div style={{fontWeight : 700}}>Hubungi Kami</div>
                                        <div style={{textAlign : "left"}}>
                                            Jonathan Sudirman<br/>
                                            (Sales Consultant)
                                        </div>
                                    </Col>
                                    <Col
                                        style={{paddingBottom : 20}}
                                        className={"d-flex flex-column justify-content-start align-items-start"}
                                        md={6}>
                                        <div style={{fontWeight : 700}}>Alamat</div>
                                        <div style={{textAlign : "left"}}>
                                            Jl. Boulevard Gading Serpong CBD Lot 1
                                            Tangerang
                                            <br/>
                                        </div>
                                    </Col>
                                    <Col
                                        style={{paddingBottom : 20}}
                                        className={"d-flex flex-column justify-content-start align-items-start"}
                                        md={6}>
                                        <div style={{fontWeight : 700}}>Telefon</div>
                                        <div style={{textAlign : "left"}}>
                                            0812 8899 7155<br/>
                                            021 29000 111 (Hunting)
                                            <br/>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col
                            className={"d-flex flex-column justify-content-start align-items-start"}
                            md={6}>
                            <div style={{fontWeight : 700}}>Tentang Kami</div>
                            <div style={{textAlign : "left"}}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                            </div>
                        </Col>
                        <Col md={12}>
                            <div style={{width : "100%", marginTop : 20}}>
                                <hr/>
                                ©Copyright Honda Sutera Indah 2021
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <a href={"https://wa.me/+6281213389818"} target="_blank">
                <div
                    style={{
                        cursor: 'pointer',
                        position: 'fixed',
                        zIndex: 9999999,
                        bottom: 25,
                        right: 25,
                        backgroundColor: '#26d367',
                        width: 48,
                        height: 48,
                        borderRadius: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: 'rgb(136, 136, 136) 0px 0px 3px 1px',
                    }}>
                    <Iconify
                        style={{fontSize : 24}}
                        color={"white"}
                        icon={"ic:baseline-whatsapp"}/>
                </div>
            </a>

        </>
    );
}
